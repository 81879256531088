<<template>
  <div class="home-page">
    <LogoLoading v-show="loading" />
    <Hero :heros="heros" class="mb-5" />
    <About :homeImage="homeImage" class="my-5" />
    <ProductHome class="my-5" />
    <Team class="my-5" />
    <Prizes class="mt-5" />
  </div>
</template>

  <script>
  // @ is an alias to /src
  import Hero from "@/components/HomePage/hero/Hero.vue";
  import About from "@/components/HomePage/about/About.vue";
  import ProductHome from "@/components/HomePage/product/ProductHome.vue";
  import Prizes from "@/components/HomePage/prizes/Prizes.vue";
  import Team from "@/components/HomePage/team/Team.vue";
  import { UTILS_HELPER } from "@/helpers/utils.js";
  import { mapState, mapActions } from 'vuex';
  import LogoLoading from "@/components/LogoLoading.vue"
  import router from '@/router';
  import InputGetList from "@/classes/InputGetList";
  import { API_HELPER } from "@/helpers/api.js";

  export default {
    metaInfo() {
      return {
        title: 'Cooperativa de Freixo de Numão',
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta:
          [
            { name: 'description', content: UTILS_HELPER.htmlToText(this.$t("intro")) },
            { name: 'keywords', content: this.$t("vueRoutes.defaultKeywords") },
            // Twitter Card
            // { name: 'twitter:card', content: "summary" },
            // { name: 'twitter:title', content: 'Cooperativa de Freixo de Numão' },
            // { name: 'twitter:description', content: UTILS_HELPER.htmlToText(this.$t("intro")) },
            // //Facebook OpenGraph
            // { property: 'og:title', content: 'Cooperativa de Freixo de Numão' },
            // { property: 'og:site_name', content: 'Cooperativa de Freixo de Numão' },
            // { property: 'og:type', content: 'website' },
            // { property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_FRONT, "/default.jpg"]) },
            // { property: 'og:description', content: UTILS_HELPER.htmlToText(this.$t("intro")) }
          ],
        link: [
          { rel: 'alternate', hreflang: 'pt', href: process.env.VUE_APP_URL_ROOT },
          { rel: 'alternate', hreflang: 'en', href: process.env.VUE_APP_URL_ROOT },
        ]
      }
    },
    name: "HomePage",
    components: {
      Hero,
      About,
      ProductHome,
      Prizes,
      Team,
      LogoLoading
    },
    mounted() {
      this.initializeHero()
    },
    computed: {
      ...mapState({
        herosOutput: state => state.heros.listOutput,
        prizesOutput: state => state.prizes.listOutput,
        membersOutput: state => state.members.listOutput
      }),
      loading: function () {
        return this.herosOutput === null;
      },
      heros() {
        let result = [];

        if (this.herosOutput && this.herosOutput.data && this.herosOutput.data.heroItems && this.herosOutput.data.heroItems.length) {
          // Find the first image of type HomeSlider
          result = this.herosOutput.data.heroItems.filter(obj => obj.types && obj.types.some(typeObj => typeObj.type === 'HomeSlider'));
        }
        return result;
      },
      homeImage() {
        let result = null;

        if (this.herosOutput && this.herosOutput.data && this.herosOutput.data.heroItems && this.herosOutput.data.heroItems.length) {
          // Find the first image of type Home
          const item = this.herosOutput.data.heroItems.find(obj => obj.types && obj.types.some(typeObj => typeObj.type === 'Home'));
          result = item && item.image ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, item.image]) : null;
        }

        return result;
      },
      members() {
        return this.membersOutput && this.membersOutput.data ? this.membersOutput.data : [];
      },
      prizes() {
        return this.prizesOutput && this.prizesOutput.data ? this.prizesOutput.data : [];
      }
    },
    methods:
    {
      ...mapActions('heros', ['getList']),

      teamClicked: function () {
        router.push({ name: "TeamDetail" });
      },
      initializeHero() {
        // GET list of hero
        const itemsPerPage = 99;
        const currentPage = 1;
        const sorter = 1; // asc
        const propertyName = "id";
        const searchText = '';

        //Make request
        var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);

        // List specific parameters
        input.visible = true;

        this.getList(input);
      }
    }
  };
</script>