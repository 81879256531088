<template>
  <div id="app">
    <template v-if="!isAgeVerified">
      <AgeBanner @isClick="isAgeVerified = true"/>
    </template>
    <template v-else>
      <router-view name="header"></router-view>
      <router-view class="body"/>
      <router-view name="footer"></router-view>
    </template>
  </div>
</template>
<script>
import AgeBanner from "./components/HomePage/AgeBanner.vue";
import Cookies from "js-cookie";
// import { mapActions, mapState } from "vuex";

export default {
  components: { AgeBanner },
  data() {
    return {
      isAgeVerified: false,
    };
  },
  mounted() {
    // this.getList();
    this.ageVerification();
  },
  computed: {
    // ...mapState('settings', ['settingsOutput']),

    // getSettings(){
    //   return this.settingsOutput && this.settingsOutput.data ? this.settingsOutput.data : null;
    // }

  },
  methods:{
    // ...mapActions('settings', ['getList']),

    ageVerification() {
      let getCookie = Cookies.get("ageVerified");
      getCookie ? this.isAgeVerified = true : this.isAgeVerified = false;
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import 'assets/css/theme.css';
@import 'assets/css/main.css';
</style>
