<template>
  <div class="products">
    <div class="container">
      <div class="row justify-content-center">
        <div class="d-flex justify-content-between align-items-center mt-5">
          <h1 class="secondary-color">{{ title.toUpperCase() }}</h1>
          <router-link class="btn-border-primary m-2 text-decoration-none" exact
            :to="{ name: 'WinePage' }">{{ $t("button.conhecaMais").toUpperCase() }}</router-link>
        </div>
        <template v-if="products.length">
          <vueper-slides style="padding: 0 50px" class="no-shadow"
            :class="{ 'big-slider': products.length > 3 }" fixed-height="650px" :visible-slides="3" :slide-ratio="1 / 2"
            :dragging-distance="70" :touchable="false" :bullets="false" :breakpoints="{
              910: { visibleSlides: 2, touchable: true },
              767: { visibleSlides: 1, slideRatio: 1 / 1, touchable: true },
            }">
            <vueper-slide v-for="(products, index) in products" :key="index">
              <template v-slot:content>
                <ProductThumb :productContent="products" @click="productsDetail(index)" />
              </template>
            </vueper-slide>
          </vueper-slides>
        </template>
        <template v-else>
          <NoResultsPlaceholder/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import ProductThumb from '@/components/HomePage/product/ProductThumb.vue';
import { mapState, mapActions } from 'vuex';
import InputGetListProducts from "@/classes/InputGetListProducts";
import i18n from '@/helpers/i18n';
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue";

export default {
  components: { VueperSlides, VueperSlide, ProductThumb, NoResultsPlaceholder },
  props: {
    title: {
      type: String,
      default: i18n.t("wines")
    },
    type: String
  },
  mounted() {
    this.initializePage();
  },
  computed: {
    ...mapState({
      productsOutput: state => state.products.listOutput,
    }),
    products() {
      return this.productsOutput && this.productsOutput.data ? this.productsOutput.data : [];
    },
  },
  methods: {
    ...mapActions('products', ['getList']),
    productsDetail(index) {
      console.log(this.products[index]);
      this.$router.push({ name: 'wineDetail', params: { products: this.products[index], productsSlug: this.products[index].slug } });
    },

    initializePage() {

      // GET list of products
      const itemsPerPage = 999;
      const currentPage = 1;
      const sorter = 1; // asc
      const propertyName = "position";
      const searchText = '';
      const visible = true;
      const type = 'Vinho';

      //Make request
      var input = new InputGetListProducts(itemsPerPage, currentPage, sorter, propertyName, searchText, visible, type);

      this.getList(input);
    },
  },
}
</script>

<style scoped>
:deep(.vueperslide) {
  transition: .3s ease-in-out;
  transform: scale(0.8);
}

:deep(.big-slider .vueperslide--active),
:deep(.big-slider .vueperslide:hover) {
  transform: scale(0.9);
}

:deep(.vueperslides--fixed-height.vueperslides--bullets-outside) {
  margin-bottom: 0;
}

:deep(.vueperslides__bullet .default) {
  border: 1px solid currentColor;
  background-color: transparent;
  box-shadow: 0 0 1px rgba(0, 0, 0, .5), 0 0 3px rgba(0, 0, 0, .3);
  transition: .4s ease-in-out;
  box-sizing: border-box;
}

:deep(.vueperslides__bullet--active .default) {
  background-color: var(--primary-color) !important;
}

@media screen and (max-width: 767px) {
  :deep(.vueperslide) {
    transition: .3s ease-in-out;
    transform: unset;
  }

  :deep(vueperslide--active) {
    transform: unset;
  }
}

:deep(.vueperslides__arrow svg) {
  stroke: black !important;
}
</style>