import Vue from "vue";
import VueRouter from "vue-router";
import i18n from '@/helpers/i18n';

//Views
import HomePage from "../views/HomePage.vue";
import OliveOilPage from "../views/OliveOilPage.vue";
import OliveOilDetail from "../views/OliveOilDetail.vue";
import LiquorPage from "../views/LiquorPage.vue";
import LiquorDetail from "../views/LiquorDetail.vue";
import WinePage from "../views/WinePage.vue";
import WineDetail from "../views/WineDetail.vue";
import Contact from "../views/Contact.vue";
import StaticInfos from "../views/StaticInfos.vue";
import TeamDetail from "../views/TeamDetail.vue";
import Gallery from "../views/Gallery.vue";

//Header and footer
import AppHeader from "../layout/AppHeader.vue";
import AppFooter from "../layout/AppFooter.vue";

// Page 404
import Page404 from "../views/Page404.vue";

Vue.use(VueRouter);

import { CONSTANTS } from '@/helpers/consts';

const routes = [
  {
    path: "/",
    name: "HomePage",
    components: {
      header: AppHeader,
      default: HomePage,
      footer: AppFooter,
    }
  },
  {
    path: i18n.t('vueRoutes.oliveOil'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.oliveOil', locale.key) ),
    name: 'OliveOil',
    components: {
      header: AppHeader,
      default: OliveOilPage,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRoutes.oliveOil')}/:oliveOilSlug`,
    alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRoutes.oliveOil', locale.key)}/:oliveOilSlug` ),
    name: 'OliveOilDetail',
    components: {
      header: AppHeader,
      default: OliveOilDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.liquor'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.liquor', locale.key) ),
    name: 'Liquor',
    components: {
      header: AppHeader,
      default: LiquorPage,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRoutes.liquor')}/:liquorSlug`,
    alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRoutes.liquor', locale.key)}/:liquorSlug` ),
    name: 'LiquorDetail',
    components: {
      header: AppHeader,
      default: LiquorDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.wine'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.wine', locale.key) ),
    name: 'wine',
    components: {
      header: AppHeader,
      default: WinePage,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRoutes.wine')}/:wineSlug`,
    alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRoutes.wine', locale.key)}/:wineSlug` ),
    name: 'wineDetail',
    components: {
      header: AppHeader,
      default: WineDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.contact'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.contact', locale.key) ),
    name: 'Contact',
    components: {
      header: AppHeader,
      default: Contact,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.gallery'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.gallery', locale.key) ),
    name: 'Gallery',
    components: {
      header: AppHeader,
      default: Gallery,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRoutes.teamDetail')}/:memberSlug`,
    alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRoutes.teamDetail', locale.key)}/:memberSlug` ),
    name: 'TeamDetail',
    components: {
      header: AppHeader,
      default: TeamDetail,
      footer: AppFooter
    },
  },
  {
    path: "/:slug",
    name: 'StaticInfos',
    components: {
      header: AppHeader,
      default: StaticInfos,
      footer: AppFooter
    },
  },
  {
    path: '*',
    name: '404',
    components: {
      header: AppHeader,
      default: Page404,
      footer: AppFooter
    }
  }, 
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
