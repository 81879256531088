<template>
  <section class="about" id="about">
        <div class="container">
            <!-- First content of about - text -->
            <div v-if="primaryInfo" class="row justify-content-center">
                <h5 v-html="primaryInfo.value" class="first-text"></h5>
                <div class="mt-4">
                    <router-link class="btn-border-primary m-2 text-decoration-none" :to="{ name: 'StaticInfos', params: {slug: 'projeto'} }">{{$t("button.descubraMais").toUpperCase()}}</router-link>
                </div>
            </div>
        </div>
        <!-- Second content of about - image -->
        <div class="mt-5">
            <div class="img-full-width-parallax" :style="{backgroundImage: 'url('+ homeImage +')'}"/>
        </div>
        <!-- Third content of about - text -->
        <div v-if="secondaryinfo" class="container-fluid container-box-bg">
            <h5 v-html="secondaryinfo.value" class="second-text"></h5>
            <div class="mt-4">
                <router-link class="btn-border-secondary m-2 text-decoration-none" :to="{ name: 'StaticInfos', params: {slug: 'projeto'} }">{{$t("button.descubraMais").toUpperCase()}}</router-link>
            </div>
        </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        homeImage: String
    },
    data() {
        return {
            primaryInfo: null,
            secondaryinfo: null
        }
    },

    mounted() 
    {
        this.getDetails("primary_info");
        this.getDetails("secondary_info");
    },
    computed: {
        ...mapState("about", ["detailOutput"]),
    },
    methods: {
        ...mapActions('about', ['getDetails']),
    },
    watch:{
        detailOutput: function(val)
        {
          if(val != null)
          {
            if(val && val.data && val.data.key === "primary_info")
            {
                this.primaryInfo = val.data;
            }
            if(val && val.data && val.data.key === "secondary_info"){
                this.secondaryinfo = val.data;
            }
          }
        }
    }

}
</script>

<style scoped>
h1{
    font-weight: bold;

}
h5{
    font-size: 1rem;
    line-height: 32px;
}
.img-full-width-parallax{
    height: 73vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    background-attachment: fixed;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
.container-box-bg {
    margin-top: -80px;
    text-align: center;
    position: relative;
    background-color: var(--primary-color);
    padding-top: 64px;
    padding-bottom: 64px;
    z-index: 2;
}
:deep(.first-text h1){
    color: var(--primary-color);
    font-weight: bold;
}
:deep(.second-text){
    color: white !important;
    font-weight: bold;
}
@media screen and (min-width: 1500px) {
    :deep(.second-text){
        padding: 0 19rem;
    }
}
@media screen and (max-width: 991px) {
    .img-full-width-parallax{
        height: 600px;
        background-attachment: scroll;
    }
}
</style>