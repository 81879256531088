<template>
  <div v-if="galleryContent" class="photo-box cursor-pointer">
    <div v-if="!isVideo" @click="showImage" class="content" :style="{'background-image': 'url(' + fileUrl + ')'}">
    </div>
    <video v-else width="100%" height="100%" controls>
      <source :src="isVideo" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";

export default {
  props: {
    galleryContent: String,
  },
  computed: {
    fileUrl() {
      return this.isVideo ? this.isVideo : API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.galleryContent]);
    },
    isVideo() {
      let typeOfVideos = ['mp4', 'avi'];
      let path = this.galleryContent;
      return typeOfVideos.some(ext => path.includes(ext)) ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, path]) : null;
    }
  },
  methods: {
    showImage() {
      this.$emit("showImage");
    }
  },
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.photo-box {
  max-width: 310px;
  max-height: 245px;
}
video {
  object-fit: cover;
}
</style>
