<template>
    <div>
      <div class="top-img" :class="{ 'image-loaded': imageLoaded }">
        <img loading="lazy" :src="wineImage" class="top-bg" @load="onImageLoad"/>
      </div>
      <div class="categories">
        <div class="container">
          <div class="col-12">
            <LogoLoading v-if="!products" />
            <div v-else class="wine-list">
              <div class="container">
                <div class="row">
                  <template v-if="products.length">
                    <div class="col-md-6 col-12" v-for="(wine, index) in products" :key="wine.id">
                      <a @click="wineDetail(index)" class="clean-link">
                        <ProductThumb class="card-oliveOil" :productContent="wine" />
                      </a>
                    </div>
                  </template>
                  <template v-else>
                    <NoResultsPlaceholder />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import InputGetList from "@/classes/InputGetList";
  import InputGetListProducts from "@/classes/InputGetListProducts";
  import { API_HELPER } from "@/helpers/api.js";
  import LogoLoading from "@/components/LogoLoading.vue"
  import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"
  import ProductThumb from '@/components/HomePage/product/ProductThumb.vue';
  
  export default {
    metaInfo() {
      return {
        title: this.$t("vueRoutes.wineTitle"),
        titleTemplate: '%s - Cooperativa de Freixo de Numão',
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta:
          [
            { name: 'description', content: this.$t("vueRoutes.wineDescription") },
            { name: 'keywords', content: this.wine && this.wine.length ? this.wine.map(x => x.name).join(", ") : this.$t("vueRoutes.wineKeywords") },
            // Twitter Card
            // { name: 'twitter:card', content: "summary" },
            // { name: 'twitter:title', content: this.$t("vueRoutes.oliveOilTitle") },
            // { name: 'twitter:description', content: this.$t("vueRoutes.oliveOilDescription") },
            // //Facebook OpenGraph
            // { property: 'og:title', content: this.$t("vueRoutes.oliveOilTitle") },
            // { property: 'og:site_name', content: 'Cooperativa de Freixo de Numão' },
            // { property: 'og:type', content: 'website' },
            // { property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_FRONT, "/default.jpg"]) },
            // { property: 'og:description', content: this.$t("vueRoutes.oliveOilDescription") },
          ],
        link: [
          { rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.wine", 'pt')]) },
          { rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.wine", 'en')]) },
        ]
      }
    },
    components: {
      LogoLoading,
      NoResultsPlaceholder,
      ProductThumb
    },
    data() {
      return {
        activeType: null,
        imageLoaded: false
      };
    },
    mounted() {
      this.initializePage();
      this.initializeHero();
    },
    computed: {
      ...mapState({
        productsOutput: state => state.products.listOutput,
        herosOutput: state => state.heros.listOutput,
      }),
      products() {
        return this.productsOutput && this.productsOutput.data ? this.productsOutput.data : null;
      },
      wineImage() {
        let result = null;
  
        if (this.herosOutput && this.herosOutput.data && this.herosOutput.data.heroItems && this.herosOutput.data.heroItems.length) {
          // Find the first image of type OliveOil
          const item = this.herosOutput.data.heroItems.find(obj => obj.types && obj.types.some(typeObj => typeObj.type === 'Wines'));
          result = item && item.image ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, item.image]) : null;
        }
  
        return result;
      },
    },
    methods: {
      ...mapActions({
        getProducts: 'products/getList',
        getHeros: 'heros/getList',
      }),
  
      wineDetail(index) {
        this.$router.push({ name: 'wineDetail', params: { products: this.products[index], wineSlug: this.products[index].slug } });
      },
      joinPaths(relativePath) {
        return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]);
      },
      initializePage() {
  
        // GET list of oliveOil
        const itemsPerPage = 999;
        const currentPage = 1;
        const sorter = 1; // asc
        const propertyName = "position";
        const searchText = '';
        const visible = true;
        const type = 'Vinho';
  
        //Make request
        var input = new InputGetListProducts(itemsPerPage, currentPage, sorter, propertyName, searchText, visible, type);
  
        this.getProducts(input);
      },
      initializeHero() {
        // GET list of hero
        const itemsPerPage = 99;
        const currentPage = 1;
        const sorter = 1; // asc
        const propertyName = "id";
        const searchText = '';
  
        //Make request
        var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
  
        // List specific parameters
        input.visible = true;
  
        this.getHeros(input);
      },
      onImageLoad() {
        this.imageLoaded = true;
      }
    }
  };
  </script>
  
  <style scoped>
  .top-img {
    position: relative;
    width: 100%;
    height: 465px;
  }
  
  .top-img .top-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .top-img::before {
    content: "";
    width: 100%;
    height: 80%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #000, rgba(84, 84, 84, 0));
    mix-blend-mode: multiply;
    opacity: 0;
    z-index: 4;
    transition: opacity 0.5s ease;
  }
  
  .top-img.image-loaded::before {
    opacity: 0.45;
  }
  
  .categories {
    padding-top: 7rem;
    padding-bottom: 80px;
  }
  
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  
  .categories .swiper-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .tab {
    cursor: pointer;
    opacity: 0.3;
  }
  
  .active {
    color: var(--primary-color);
    opacity: 1;
  }
  
  .categories .tab {
    width: auto;
  }
  
  .tab {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .categories .category,
  .categories .year {
    display: inline-block;
    font-size: 16px;
    line-height: 23px;
    padding: 11px 32px;
    text-transform: uppercase;
    white-space: nowrap;
    color: inherit;
    text-decoration: none;
    font-weight: bold;
  }
  
  .categories .category:hover {
    opacity: 1;
    color: var(--primary-color);
  }
  
  .card-oliveOil {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 7rem;
  }
  
  .card-oliveOil:hover {
    transform: scale(1.1);
  }
  
  :deep(.card-oliveOil img) {
    max-height: 480px;
    height: 480px;
    width: auto;
    margin-bottom: 20px;
    transition: all .4s ease-in-out;
  }
  
  .clean-link {
    color: inherit;
    text-decoration: none;
  }
  
  :deep(.card-oliveOil .name) {
    font-size: 22px;
    line-height: 28px;
    max-width: 270px;
    color: var(--primary-color);
    margin-bottom: 10px;
    text-align: center;
  }
  
  :deep(.card-oliveOil .category) {
    color: var(--secondary-color);
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    max-width: 270px;
  }
  
  @media screen and (max-width: 991px) {
    .categories .swiper-wrapper {
      display: block;
    }
  }
  
  :deep(.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom)) {
    height: unset !important;
  }
  </style>
  