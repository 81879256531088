/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const MESSAGES_SERVICE = {
    send,
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Messages";

/* Services */
function send(message)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    
    
    var raw = JSON.stringify(message);

    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: raw
    }; 

    // Process API call
    return API_HELPER.apiCall(url, null, requestOptions);
}